<template>
  <div class="container" ref="box">
    <div ref="header" class="header pageHeader">
      <a-page-header title="系统设置"> </a-page-header>
    </div>
    <a-tabs
      :activeKey="key"
      @change="changeTab"
      style="flex: 1; background: #fff; margin-top: 20px; border-radius: 8px"
    >
      <a-tab-pane key="3" tab="首页签到红包">
        <a-form
          :form="indexform"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
          @submit="indexhandleSubmit"
          style="margin-top: 24px"
        >
          <a-form-item label="名称：">
            <a-input
              v-decorator="[
                'name',
                {
                  rules: [{ required: true, message: '请输入名称!' }],
                },
              ]"
            />
          </a-form-item>
          <a-form-item label="地址：">
            <a-input
              v-decorator="[
                'url',
                {
                  rules: [{ required: true, message: '请输入地址!' }],
                },
              ]"
            />
          </a-form-item>
          <a-form-item :wrapper-col="{ span: 14, offset: 4 }">
            <a-button type="primary" html-type="submit"> 保存 </a-button>
          </a-form-item>
        </a-form>
      </a-tab-pane>
      <a-tab-pane key="1" tab="客服信息">
        <a-form
          :form="serviceform"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
          @submit="servicehandleSubmit"
          style="margin-top: 24px"
        >
          <a-form-item label="客服名称：">
            <a-input
              v-decorator="[
                'name',
                {
                  rules: [{ required: true, message: '请输入客服名称!' }],
                },
              ]"
            />
          </a-form-item>
          <a-form-item label="客服二维码：" extra="最多支持1张">
            <a-input
              v-decorator="[
                'image_url',
                {
                  rules: [{ required: true, message: '请选择客服二维码!' }],
                },
              ]"
              type="hidden"
            ></a-input>
            <a-upload
              name="file"
              list-type="picture"
              class="upload-list-inline"
              :fileList="fileList"
              :customRequest="(value) => changeImg(value, 'service')"
              @change="getchangeImg"
              :remove="removeImg"
              :beforeUpload="handleBeforeUpload"
            >
              <a-button :disabled="!uploadBtn">
                <a-icon type="upload" /> 上传图片
              </a-button>
            </a-upload>
          </a-form-item>
          <a-form-item label="客服描述：">
            <a-input
              v-decorator="[
                'describe',
                {
                  rules: [{ required: true, message: '请输入客服描述!' }],
                },
              ]"
            />
          </a-form-item>
          <a-form-item :wrapper-col="{ span: 14, offset: 4 }">
            <a-button type="primary" html-type="submit"> 保存 </a-button>
          </a-form-item>
        </a-form>
      </a-tab-pane>
      <a-tab-pane key="2" tab="APP版本">
        <a-form
          :form="appform"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
          @submit="apphandleSubmit"
          style="margin-top: 24px"
        >
          <a-col :span="24" class="title">安卓版本:</a-col>
          <a-form-item label="版本号：">
            <a-input
              v-decorator="[
                'android.version',
                {
                  rules: [{ required: true, message: '请输入版本号!' }],
                },
              ]"
            />
          </a-form-item>
          <a-form-item label="下载地址：">
            <a-input
              v-decorator="[
                'android.install_url',
                {
                  rules: [{ required: true, message: '请输入下载地址!' }],
                },
              ]"
            />
          </a-form-item>
          <a-form-item label="版本描述">
            <a-input
              v-decorator="[
                'android.describe',
                {
                  rules: [{ required: true, message: '请输入版本描述!' }],
                },
              ]"
              placeholder="请输入版本描述"
              type="textarea"
            />
          </a-form-item>
          <a-col :span="24" class="title">苹果版本:</a-col>
          <a-form-item label="版本号：">
            <a-input
              v-decorator="[
                'ios.version',
                {
                  rules: [{ required: true, message: '请输入版本号!' }],
                },
              ]"
            />
          </a-form-item>
          <a-form-item label="下载地址：">
            <a-input
              v-decorator="[
                'ios.install_url',
                {
                  rules: [{ required: true, message: '请输入下载地址!' }],
                },
              ]"
            />
          </a-form-item>
          <a-form-item label="版本描述">
            <a-input
              v-decorator="[
                'ios.describe',
                {
                  rules: [{ required: true, message: '请输入版本描述!' }],
                },
              ]"
              placeholder="请输入版本描述"
              type="textarea"
            />
          </a-form-item>
          <a-form-item :wrapper-col="{ span: 14, offset: 4 }">
            <a-button type="primary" html-type="submit"> 保存 </a-button>
          </a-form-item>
        </a-form>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>
<script>
export default {
  name: "User",
  data() {
    return {
      serviceform: this.$form.createForm(this, { name: "servicesearch" }),
      appform: this.$form.createForm(this, { name: "appsearch" }),
      indexform: this.$form.createForm(this, { name: "indexform" }),
      key: "3",
      labelCol: { span: 3 },
      wrapperCol: { span: 16 },
      uploadBtn: true,
      fileList: [],
      canUpload: "",
    };
  },
  mounted() {
    if (this.key == 1) {
      this.getService();
    }
    if (this.key == 2) {
      this.getAPP();
    }
      if (this.key == 3) {
        this.getIndex();
      }
  },
  methods: {
    changeTab(key) {
      this.key = key;
      if (this.key == 1) {
        this.getService();
      }
      if (this.key == 2) {
        this.getAPP();
      }
      if (this.key == 3) {
        this.getIndex();
      }
    },
    //获取客服信息
    getService() {
      var that = this;
      that.$message.loading({ content: "加载中..", key: "load", duration: 0 });
      that.axios
        .get("/api/admin/systemConfig/get/customer_service")
        .then((res) => {
          that.$message.loading({
            content: "加载中..",
            key: "load",
            duration: 0.00001,
          });
          if (that.$code(res)) {
            that.fileList = [
              {
                uid: "-1",
                name: "",
                status: "done",
                url: res.data.resultObject.image_url,
                thumbUrl: res.data.resultObject.image_url,
              },
            ];
            if (res.data.resultObject.image_url) {
              that.uploadBtn = false;
            } else {
              that.uploadBtn = true;
            }
            this.serviceform.setFieldsValue({
              name: res.data.resultObject.name,
              describe: res.data.resultObject.describe,
              image_url: res.data.resultObject.image_url,
            });
          }
        });
    },
    //上传客服信息
    servicehandleSubmit(e) {
      e.preventDefault();
      var that = this;
      that.serviceform.validateFields((error, values) => {
        if (!error) {
          var data = values;
          that.axios
            .post("/api/admin/systemConfig/set/customer_service", data)
            .then((res) => {
              if (that.$code(res)) {
                that.$message.success("保存成功");
              }
            });
        }
      });
    },
    //上传图片
    changeImg(info, txt) {
      var that = this;
      const formData = new FormData();
      formData.append("file", info.file);
      that.$message.loading({ content: "加载中..", key: "load", duration: 0 });
      that.axios
        .post("/api/admin/systemConfig/fileUpload", formData)
        .then((res) => {
          that.$message.loading({
            content: "加载中..",
            key: "load",
            duration: 0.00001,
          });
          if (that.$code(res)) {
            if (txt == "service") {
              var img = res.data.resultObject.path_url;
              that.serviceform.setFieldsValue({
                image_url: img,
              });
              that.fileList = [
                {
                  uid: info.file.uid,
                  name: info.file.name,
                  status: "done",
                  url: "" + img,
                  thumbUrl: "" + img,
                },
              ];
            }
          }
        });
    },
    //删除图片
    removeImg() {
      this.fileList = [];
    },
    //上传前校验
    handleBeforeUpload(file) {
      if (file.type.indexOf("image") < 0) {
        this.$message.error("仅支持上传图片");
        this.canUpload = false;
        return false;
      } else {
        this.canUpload = true;
        return true;
      }
    },
    getchangeImg(info) {
      if (!this.canUpload) {
        info.fileList = [];
      }
      if (info.fileList.length >= 1) {
        this.uploadBtn = false;
      } else {
        this.uploadBtn = true;
        if (info.fileList.length == 0) {
          this.serviceform.setFieldsValue({
            image_url: "",
          });
        }
      }
    },
    //获取APP版本
    getAPP() {
      var that = this;
      that.$message.loading({ content: "加载中..", key: "load", duration: 0 });
      that.axios
        .get("/api/admin/systemConfig/get/version_check")
        .then((res) => {
          that.$message.loading({
            content: "加载中..",
            key: "load",
            duration: 0.00001,
          });
          if (that.$code(res)) {
            this.appform.setFieldsValue({
              android: {
                version: res.data.resultObject.android.version,
                install_url: res.data.resultObject.android.install_url,
                describe: res.data.resultObject.android.describe,
              },
              ios: {
                version: res.data.resultObject.ios.version,
                install_url: res.data.resultObject.ios.install_url,
                describe: res.data.resultObject.ios.describe,
              },
            });
          }
        });
    },
    //上传APP版本
    apphandleSubmit(e) {
      e.preventDefault();
      var that = this;
      that.appform.validateFields((error, values) => {
        if (!error) {
          var data = values;
          that.axios
            .post("/api/admin/systemConfig/set/version_check", data)
            .then((res) => {
              if (that.$code(res)) {
                that.$message.success("保存成功");
              }
            });
        }
      });
    },
    //获取首页签到红包
    getIndex() {
      var that = this;
      that.$message.loading({ content: "加载中..", key: "load", duration: 0 });
      that.axios
        .get("/api/admin/systemConfig/get/check_in_red_envelope")
        .then((res) => {
          that.$message.loading({
            content: "加载中..",
            key: "load",
            duration: 0.00001,
          });
          if (that.$code(res)) {
            this.indexform.setFieldsValue({
              name: res.data.resultObject.name,
              url: res.data.resultObject.url,
            });
          }
        });
    },
    //上传首页签到红包
    indexhandleSubmit(e) {
      e.preventDefault();
      var that = this;
      that.indexform.validateFields((error, values) => {
        if (!error) {
          var data = values;
          that.axios
            .post("/api/admin/systemConfig/set/check_in_red_envelope", data)
            .then((res) => {
              if (that.$code(res)) {
                that.$message.success("保存成功");
              }
            });
        }
      });
    },
  },
};
</script>
<style scoped>
/deep/.pageHeader .ant-page-header,
/deep/.pageHeader .ant-page-header-heading {
  height: auto;
  border: 0 !important;
}
.ant-form {
  padding-left: 20px;
  padding-right: 20px;
}
/deep/.ant-form-item {
  margin-right: 10px;
}

/deep/.ant-table-body::-webkit-scrollbar {
  background-color: #fff;
  width: 10px;
  height: 10px;
}
/deep/.ant-table-body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f0f2f5;
}
/deep/.ant-pagination .ant-pagination-item,
/deep/.ant-pagination .ant-pagination-jump-prev,
/deep/.ant-pagination .ant-pagination-jump-next {
  display: none;
}
.ant-btn {
  height: 36px;
  border-color: #f8f8f8;
}
.ant-btn:hover,
.ant-btn:focus {
  color: #666;
  border-color: rgba(253, 105, 150);
}
form .ant-btn:hover,
form .ant-btn:focus {
  color: #fff;
}
.ant-upload .ant-btn:hover,
.ant-upload .ant-btn:focus {
  color: #666;
}
.ant-table .ant-btn {
  height: 30px;
  padding: 0 20px;
}
.ant-input {
  width: 100%;
}
.ant-form-item {
  height: auto;
}
</style>