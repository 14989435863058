var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"box",staticClass:"container"},[_c('div',{ref:"header",staticClass:"header pageHeader"},[_c('a-page-header',{attrs:{"title":"系统设置"}})],1),_c('a-tabs',{staticStyle:{"flex":"1","background":"#fff","margin-top":"20px","border-radius":"8px"},attrs:{"activeKey":_vm.key},on:{"change":_vm.changeTab}},[_c('a-tab-pane',{key:"3",attrs:{"tab":"首页签到红包"}},[_c('a-form',{staticStyle:{"margin-top":"24px"},attrs:{"form":_vm.indexform,"label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol},on:{"submit":_vm.indexhandleSubmit}},[_c('a-form-item',{attrs:{"label":"名称："}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'name',
              {
                rules: [{ required: true, message: '请输入名称!' }],
              } ]),expression:"[\n              'name',\n              {\n                rules: [{ required: true, message: '请输入名称!' }],\n              },\n            ]"}]})],1),_c('a-form-item',{attrs:{"label":"地址："}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'url',
              {
                rules: [{ required: true, message: '请输入地址!' }],
              } ]),expression:"[\n              'url',\n              {\n                rules: [{ required: true, message: '请输入地址!' }],\n              },\n            ]"}]})],1),_c('a-form-item',{attrs:{"wrapper-col":{ span: 14, offset: 4 }}},[_c('a-button',{attrs:{"type":"primary","html-type":"submit"}},[_vm._v(" 保存 ")])],1)],1)],1),_c('a-tab-pane',{key:"1",attrs:{"tab":"客服信息"}},[_c('a-form',{staticStyle:{"margin-top":"24px"},attrs:{"form":_vm.serviceform,"label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol},on:{"submit":_vm.servicehandleSubmit}},[_c('a-form-item',{attrs:{"label":"客服名称："}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'name',
              {
                rules: [{ required: true, message: '请输入客服名称!' }],
              } ]),expression:"[\n              'name',\n              {\n                rules: [{ required: true, message: '请输入客服名称!' }],\n              },\n            ]"}]})],1),_c('a-form-item',{attrs:{"label":"客服二维码：","extra":"最多支持1张"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'image_url',
              {
                rules: [{ required: true, message: '请选择客服二维码!' }],
              } ]),expression:"[\n              'image_url',\n              {\n                rules: [{ required: true, message: '请选择客服二维码!' }],\n              },\n            ]"}],attrs:{"type":"hidden"}}),_c('a-upload',{staticClass:"upload-list-inline",attrs:{"name":"file","list-type":"picture","fileList":_vm.fileList,"customRequest":function (value) { return _vm.changeImg(value, 'service'); },"remove":_vm.removeImg,"beforeUpload":_vm.handleBeforeUpload},on:{"change":_vm.getchangeImg}},[_c('a-button',{attrs:{"disabled":!_vm.uploadBtn}},[_c('a-icon',{attrs:{"type":"upload"}}),_vm._v(" 上传图片 ")],1)],1)],1),_c('a-form-item',{attrs:{"label":"客服描述："}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'describe',
              {
                rules: [{ required: true, message: '请输入客服描述!' }],
              } ]),expression:"[\n              'describe',\n              {\n                rules: [{ required: true, message: '请输入客服描述!' }],\n              },\n            ]"}]})],1),_c('a-form-item',{attrs:{"wrapper-col":{ span: 14, offset: 4 }}},[_c('a-button',{attrs:{"type":"primary","html-type":"submit"}},[_vm._v(" 保存 ")])],1)],1)],1),_c('a-tab-pane',{key:"2",attrs:{"tab":"APP版本"}},[_c('a-form',{staticStyle:{"margin-top":"24px"},attrs:{"form":_vm.appform,"label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol},on:{"submit":_vm.apphandleSubmit}},[_c('a-col',{staticClass:"title",attrs:{"span":24}},[_vm._v("安卓版本:")]),_c('a-form-item',{attrs:{"label":"版本号："}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'android.version',
              {
                rules: [{ required: true, message: '请输入版本号!' }],
              } ]),expression:"[\n              'android.version',\n              {\n                rules: [{ required: true, message: '请输入版本号!' }],\n              },\n            ]"}]})],1),_c('a-form-item',{attrs:{"label":"下载地址："}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'android.install_url',
              {
                rules: [{ required: true, message: '请输入下载地址!' }],
              } ]),expression:"[\n              'android.install_url',\n              {\n                rules: [{ required: true, message: '请输入下载地址!' }],\n              },\n            ]"}]})],1),_c('a-form-item',{attrs:{"label":"版本描述"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'android.describe',
              {
                rules: [{ required: true, message: '请输入版本描述!' }],
              } ]),expression:"[\n              'android.describe',\n              {\n                rules: [{ required: true, message: '请输入版本描述!' }],\n              },\n            ]"}],attrs:{"placeholder":"请输入版本描述","type":"textarea"}})],1),_c('a-col',{staticClass:"title",attrs:{"span":24}},[_vm._v("苹果版本:")]),_c('a-form-item',{attrs:{"label":"版本号："}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'ios.version',
              {
                rules: [{ required: true, message: '请输入版本号!' }],
              } ]),expression:"[\n              'ios.version',\n              {\n                rules: [{ required: true, message: '请输入版本号!' }],\n              },\n            ]"}]})],1),_c('a-form-item',{attrs:{"label":"下载地址："}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'ios.install_url',
              {
                rules: [{ required: true, message: '请输入下载地址!' }],
              } ]),expression:"[\n              'ios.install_url',\n              {\n                rules: [{ required: true, message: '请输入下载地址!' }],\n              },\n            ]"}]})],1),_c('a-form-item',{attrs:{"label":"版本描述"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'ios.describe',
              {
                rules: [{ required: true, message: '请输入版本描述!' }],
              } ]),expression:"[\n              'ios.describe',\n              {\n                rules: [{ required: true, message: '请输入版本描述!' }],\n              },\n            ]"}],attrs:{"placeholder":"请输入版本描述","type":"textarea"}})],1),_c('a-form-item',{attrs:{"wrapper-col":{ span: 14, offset: 4 }}},[_c('a-button',{attrs:{"type":"primary","html-type":"submit"}},[_vm._v(" 保存 ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }